import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Contexts
import useUi from "./contexts/ui/useUi";
import useAuth from "./contexts/auth/useAuth";

// Pages
import Login from "./pages/login";
import Admin from "./pages/admin";
import Painel from "./pages/painel";
import CreateUser from "./pages/createUser";
import UpdatePassword from "./pages/updatePassword";
import { Loader } from "./pages/painel/styles";
import History from "./pages/history";
import Report from "./pages/report";
import UnderMaintenance from "./pages/underMaintenance";

const App: React.FC = () => {
  const { theme } = useUi();
  const { loadingUser } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      {loadingUser ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/createUser' element={<CreateUser />} />
            <Route path='/history' element={<History />} />
            <Route path='/updatePassword' element={<UpdatePassword />} />
            <Route path='/' element={<Painel />} />
            <Route path='/report' element={<Report />} />
            {/* <Route path='/' element={<UnderMaintenance />} /> */}
          </Routes>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
